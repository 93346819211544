import React, { Component } from "react";
import Layout from "../layout/layout";
import TermsAndConditions from "../components/Terms/terms";
import TermsAndConditionsMy from "../components/Terms/termsMy";

class TermsAndConditionsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      realm : ''
    };
  }
  
  componentDidMount() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let realm = params.get("realm");
    realm = (!realm) ? 'PH' : realm?.toUpperCase();
    this.setState({
      realm: realm
    });
  }
  render() {
    return (
      <Layout location={this.props.location} title="Terms and Conditions">
        <div className="terms-container">
          {(this.state.realm === "MY") ? <TermsAndConditionsMy /> : <TermsAndConditions />}
        </div>
      </Layout>
    );
  }
}

export default TermsAndConditionsPage;
